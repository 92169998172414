@import '../../config';

$project-distance: 40vh;
$project-height: 25rem;

$project-distance-multiplier: 1.5;

$project-linking-circle-size: .5rem;

footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-top: $project-distance;

    @media #{$small-and-down} {
        padding-top: calc($project-distance * $project-distance-multiplier)
    }

    .storyBubblesContainer {
        top: 0;
        z-index: 1;
    }

    a,
    a:link,
    a:visited {
        color: $text-color;
        display: block;
    }
}