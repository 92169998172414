// IMPORTS

@import './fonts/fonts.scss';


// COLORS

$black: black;
$white: white;
$orange: #FAA31A;

$gray: #a4a4a4;

$container-color: $white;
$text-color: $black;
$text-color-inverted: $white;

$color-border: $black;

// FONT

$font-family-default: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$font-size-default: 18px;
$font-size: 1rem;
$line-height: #{$font-size-default * 1.6};


//  MEDIA-QUERIES

$small-screen-up:           601px   !default;
$medium-screen-up:          993px   !default;
$large-screen-up:           1201px  !default;
$small-screen:              600px   !default;
$medium-screen:             1200px  !default;
$medium-screen-portrait:    1024px  !default;
$large-screen:              1400px  !default;
$larger-screen:             1500px  !default;

$full-size-menu-breakpoint: 1430px  !default;

$small-and-up:              "only screen and (min-width : #{$small-screen-up})" !default;
$medium-and-up:             "only screen and (min-width : #{$medium-screen-up})" !default;
$large-and-up:              "only screen and (min-width : #{$large-screen-up})" !default;

$small-and-down:            "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down:           "only screen and (max-width : #{$medium-screen})" !default;
$large-and-down:            "only screen and (max-width : #{$large-screen})" !default;
$larger-and-down:           "only screen and (max-width : #{$larger-screen})" !default;
$medium-and-down-portrait:  "only screen and (max-width : #{$medium-screen-portrait})" !default;

$medium-only:               "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
$large-only:                "only screen and (min-width : #{$medium-screen-up}) and (max-width : #{$large-screen})" !default;

$browser-IE:                "screen and (-ms-high-contrast: active), (-ms-high-contrast: none)";

$fold-menu:                 "only screen and (max-width : #{$full-size-menu-breakpoint})" !default;

@import './general.scss';