h1{ font-size: 3rem; }
h2{ font-size: 1.5rem; }
h3{ font-size: 1.17rem; }
h4{ font-size: 1rem; }
h5{ font-size: .83rem; }
h6{ font-size: .67rem; }

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: normal;
    margin: 1.75rem 0;
}

strong {
    font-weight: bold;
}

p {
    margin: 1.5rem 0;
}

img {
    width: 100%;
}

figcaption {
    border-left: 3px solid $gray;
    color: $gray;
    font-size: .7rem;
    padding-left: .5rem;
}

.hiring {
    align-items: center;
    background-color: $orange;
    border-top-left-radius: 1rem;
    bottom: 0;
    color: $text-color-inverted;
    display: flex;
    font-weight: 700;
    justify-content: center;
    padding: 0.5rem 1rem;
    position: fixed;
    right: 0;
    text-decoration: none;
    z-index: 999;
}