@import '../../config';

$border-radius: 1.5rem;

$project-distance: 40vh;
$project-height: 41.8rem;
$project-width: 50rem;

$project-distance-multiplier: 1.5;

$project-linking-circle-size: .5rem;

.containerList {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    li {
        padding-top: $project-distance;
        pointer-events: none;

        @media #{$small-and-down} {
            padding-top: calc($project-distance * $project-distance-multiplier);
        }

        .storyContentContainer {
            @media #{$small-and-down} {
                flex-direction: column;
                justify-content: flex-end;
                padding-bottom: 6rem;

                >* {
                    justify-content: center;
                    width: 100%;

                    >*,
                    >.readmore {
                        align-self: center;
                        justify-content: center;
                        text-align: center;
                    }
                }

                .storyHeader {
                    border: none;
                    margin-bottom: 0;
                    margin-top: 2rem;

                    h1 {
                        font-size: 1.6rem;
                    }
                }
            }
        }

        .containerStory {
            pointer-events: initial;

            svg.projectBubbleClipper,
            svg.projectBubbleMobileClipper {
                position: relative;
                z-index: -1;


                image {
                    // filter: blur(5px) grayscale(100%);
                    transform-origin: 50%;
                    transition: filter .5s ease-in-out, transform .5s ease-in-out;
                }

                @media #{$small-and-down} {
                    left: -20%;
                    width: 140%;

                    image {
                        height: 101%;
                        width: 110%;
                    }
                }
            }

            svg:not(.projectBubbleClipper):not(.projectBubbleMobileClipper) {
                transition: transform .5s cubic-bezier(0.800, 0.210, 0.360, 1.320);

                @media #{$small-and-down} {
                    max-height: 60vh;
                    max-width: 80vw;
                }
            }

            @media #{$medium-and-up} {
                &:hover {

                    svg.projectBubbleClipper,
                    svg.projectBubbleMobileClipper {
                        image {
                            // filter: blur(3px) grayscale(50%);
                            transform: scale(1.05);
                            transition: filter .5s ease-in-out, transform .5s ease-in-out;


                        }
                    }

                    svg {
                        transform: scale(1);
                    }

                    svg:not(.projectBubbleClipper):not(.projectBubbleMobileClipper) {
                        transform: scale(1.05);
                        transition-delay: .2s;
                        transition: transform .5s cubic-bezier(0.800, 0.210, 0.360, 1.320);
                    }
                }
            }
        }

        .storyBubblesContainer {
            pointer-events: none;
        }

        &:nth-child(2n+2) {
            @media #{$medium-and-up} {
                .storyContentContainer {
                    flex-direction: row-reverse;
                }

                .storyDevices {
                    justify-content: flex-end;

                    .device {
                        margin-left: 1rem;
                        margin-right: 0;
                    }
                }

                .containerStory {

                    .readmore {
                        align-self: flex-end;
                    }

                    svg.projectBubbleClipper {
                        transform: scaleX(-1);

                        g image {
                            transform: scaleX(-1);
                        }
                    }

                    .storyHeader {
                        border-left: none;
                        border-right: 5px dotted $container-color;
                        color: $text-color-inverted;
                        margin: auto;
                        max-width: calc(#{$project-width} - 4rem);
                        padding: 0 2rem;
                        text-align: right;
                        text-shadow: 0rem .3rem .8rem rgba(0, 0, 0, .5);
                        top: 0;
                    }
                }
            }
        }
    }
}

.containerStory {
    border-radius: $border-radius;
    max-height: $project-height;
    max-width: calc(#{$project-width} - 4rem);
    position: relative;
    width: 100vw;
    z-index: 1;

    .storyCoverImage {
        border-radius: $border-radius;
    }

    .storyHeader {
        align-items: flex-start;
        border-left: 5px dotted $container-color;
        color: $text-color-inverted;
        margin: auto;
        max-width: calc(#{$project-width} - 4rem);
        padding: 0 2rem;
        text-shadow: 0rem .3rem .8rem rgba(0, 0, 0, .5);
        top: 0;

        h1 {
            margin: 0;
            text-shadow: 0 0 1rem black;
        }
    }

    &Opened {
        background-color: $container-color;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        height: 100%;
        max-height: 100vh;
        max-width: 100vw;
        overflow-y: auto;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 2;

        .storyCoverImage {
            background-attachment: fixed;
            border-radius: 0;
            left: 0;
            max-height: 75vh;
            top: 0;

            &.propositionCoverImage{
                align-items: center;
                display: flex;
                justify-content: center;
                @media #{$small-and-down} {
                    position: fixed;
                }
            }

            @media #{$small-and-down} {
                background-attachment: scroll;
                transform: translateX(0%) translateY(0%) translateZ(0px) !important;
                transform-origin: 0 0 0 !important;
            }
        }

        .storyContentContainer {
            bottom: calc(25vh + 10rem);
            color: $text-color-inverted;
            display: flex;
            flex-direction: row-reverse;
            height: auto;
            left: 0;
            max-height: calc(75vh - 10rem);
            max-width: calc(#{$project-width} - 4rem);
            margin: 0 auto;
            right: 0;
            padding: 2rem 0;
            position: fixed;
            z-index: -2;

            .storyHeader {
                align-items: flex-end;
                border-right: 5px dotted $container-color;
                display: flex;
                margin: auto;
                padding: 0 2rem;
                text-align: right;

                .storyDevices {
                    justify-content: flex-end;
                }

                h1 {
                    margin: 0;
                    text-shadow: 0 0 1rem black;
                }
            }

            @media #{$small-and-down} {
                top: 0;

                >div:first-child {
                    display: none;
                }

                .storyHeader {
                    align-items: flex-start;
                    border-right: none;
                    text-align: left;
                    width: 100%;

                    .storyDevices {
                        justify-content: flex-start;
                    }

                    h1 {
                        font-size: 2.5rem;
                    }
                }
            }

            &.propositionContentContainer{
                bottom: 50vh;
                @media #{$small-and-down} {
                    bottom: auto;
                    top: 35vh;
                }
                .storyHeader{
                    align-items: center;
                    border: 0;
                    text-align: center;
                    @media #{$small-and-down} {
                        display: block;
                        margin: auto 0 0 0;
                    }
                }
            }
        }

    }
}

.storyBubblesContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 30vh;
    padding: 5vh 0;
    position: absolute;
    top: -$project-distance;
    width: 100%;
    z-index: -2;

    @media #{$small-and-down} {
        height: calc($project-distance * $project-distance-multiplier);
        overflow: hidden;
        top: calc(-#{$project-distance} * #{$project-distance-multiplier});
    }
}

.storyBubbleContainer {
    cursor: default;
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    top: 0;
    width: 100%;
    z-index: 0;
}

.storyCoverImage {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    max-height: 100vh;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: -2;
}

.storyCard {
    background-color: $container-color;
    border-radius: $border-radius;
    box-shadow: 0 0 20rem -6rem black;
    height: fit-content;
    margin: 0 auto;
    max-width: calc(#{$project-width} - 4rem);
    padding: 2rem 4rem;
}

.storyCard:not(.storyContact) {
    padding-top: 3rem;
}

.storyContent {
    margin-top: calc(75vh - 10rem);

    a,
    a:link,
    a:visited {
        color: $text-color;
    }

    figure {
        margin-bottom: 1rem;
    }

        &.propositionContent {
            h2{
                a{
                    border-bottom: 1px solid #ebebeb;
                    border-top: 1px solid #ebebeb;
                    display: block;
                    padding: 2rem;
                    transform: scale(1);
                    transition: transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                    will-change: transform;
                    &:hover{
                        transform: scale(1.035);
                        transition: transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                        will-change: transform;
                    }
                }
            }
        }
}

.storyDevices {
    display: flex;
    margin-top: 1rem;
    width: 100%;

    .device {
        align-items: center;
        background-color: $container-color;
        border-radius: 3rem;
        display: flex;
        height: 3rem;
        justify-content: center;
        margin-right: 1rem;
        pointer-events: initial;
        width: 3rem;

        &:last-child {
            margin-right: 0;
        }

        svg path,
        svg rect {
            stroke: black;
        }

        @media #{$small-and-down} {
            height: 2.5rem;
            width: 2.5rem;
        }
    }
}

.storyContentContainer {
    display: flex;
    height: 100%;
    position: absolute;
    pointer-events: none;
    width: 100%;

    >* {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
    }

    &.proposition{
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .storyHeader{
            align-items: center;
            border: 0;
            margin: 0;
            text-align: center;
            a{
                align-self: center;
            }
        }
    }

    div:first-child:empty {
        width: 0;

        +div {
            align-items: center;
            border: none;
            width: 100%;

            .readmore {
                align-self: center;
            }

            @media #{$small-and-down} {
                position: relative;
                top: -6rem;

                h1 {
                    font-size: 3rem;
                }
            }
        }
    }
}

.storyContact {
    margin-top: 10rem;
    width: 100%;
    transition: height .5s cubic-bezier(0.800, 0.210, 0.360, 1.320);
    will-change: height;
}

.storiesBackgroundBubbles {
    display: flex;
    height: 100vh;
    justify-content: center;
    pointer-events: none;
    overflow: hidden;
    top: 0;
    transition: opacity 1s cubic-bezier(0.800, 0.210, 0.360, 1.320), transform 1s cubic-bezier(0.800, 0.210, 0.360, 1);
    width: 100%;
    will-change: opacity, transform;
}

.storyCoverGradientOverlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    height: 50%;
    opacity: .7;
    position: absolute;
    width: 100%;
}

.projectBubbleMobileClipper {
    display: none;
}

@media #{$small-and-down} {
    .storyCard {
        padding: 1rem 2rem;
    }

    .projectBubbleClipper {
        display: none;
    }

    .projectBubbleMobileClipper {
        display: block;
    }

}