@import '../../config';

.container {
    display: flex;
    // grid-template-columns: [start] 45% [line1] auto [end];
    position: relative;

    .contactContainer {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom-left-radius: 1.5rem;
        border-top-left-radius: 1.5rem;
        margin-bottom: -2rem;
        margin-left: -4rem;
        margin-top: -2rem;
        overflow: hidden;
        position: relative;
        width: 55%;

        svg {
            bottom: 0rem;
            left: 0;
            opacity: .75;
            position: absolute;
        }
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        padding-left: 4rem;
        position: relative;
        width: 55%;
    }

    .employeeData {
        bottom: 0;
        color: $text-color-inverted;
        padding: 1rem;
        position: absolute;
        width: 100%;
        z-index: 1;

        h2 {
            margin: .5rem 0;
        }

        div {
            font-size: .8rem;

            a {

                &:link,
                &:visited {
                    color: $text-color-inverted;
                    text-decoration: none;
                }
            }
        }
    }

    .captchaContainer {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
        width: 100%;

        canvas {
            width: 150px;
        }

        >div {
            font-size: .75rem;
        }
    }

    >canvas {
        border-radius: 2rem;
        height: calc(100% + 4rem);
        left: -4rem !important;
        top: -2rem !important;
        width: calc(100% + 8rem);
    }

    @media #{$small-and-down} {
        flex-direction: column;

        .contactContainer {
            border-bottom-left-radius: 0;
            border-top-right-radius: 1.5rem;
            height: 25rem;
            margin-bottom: 1rem;
            margin-left: -2rem;
            margin-top: -1rem;
            width: calc(100% + 4rem);

            svg {
                bottom: -20vw;
                height: auto;
                width: 100%;
            }
        }

        .formContainer {
            padding: 0;
            width: 100%;
        }
    }
}