@import '../../config';

.tierscontainer {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 .5rem 3rem .25rem rgba(0, 0, 0, .25);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    @media #{$large-and-up} {
        flex-direction: row;
        left: -11rem;
        margin: 3rem 0;
        width: 60rem;
    }

    > .icon {
        background-color: rgb(210, 210, 210);
        border-radius: 100%;
        color: white;
        display: flex;
        position: absolute;
        left: -1rem;
        margin-left: calc(60rem / 4);
        padding: .25rem;
        top: 50%;
        width: 2rem;
        z-index: 1;
    }
}

.tierContainer {
    background-color: white;
    border-radius: 1rem;
    display: block;
    outline: 1px solid rgba(243, 243, 243, 0);
    overflow: hidden;
    padding: 0 1.5rem 1.5rem 1.5rem;
    position: relative;
    transition: outline .3s ease-in-out, transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275) .3s;
    will-change: outline, transform;
    @media #{$large-and-up} {
        width: calc(60rem / 4 + 12rem);
    }

    &:hover {
        outline: 1px solid rgba(53, 203, 123, .25);
        transform: scale(1.035);
        transition: outline .3s ease-in-out .15s, transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        will-change: outline, transform;
        z-index: 2;
    }

    &[data-selected="true"] {
        outline: 1px solid rgba(53, 203, 123, 1);
        transform: scale(1.1);
        transition: outline .3s ease-in-out .15s, transform .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        will-change: outline, transform;
        z-index: 1;
        &:after{
            background-color: rgb(53, 203, 123);
            border-radius: 1rem;
            content: '';
            height: .5rem;
            right: .5rem;
            position: absolute;
            top: 1rem;
            width: .5rem;
        }
    }

    h2 {
        font-size: 1rem;
    }

    .icon {
        background-color: rgb(210, 210, 210);
        border-radius: 100%;
        color: white;
        display: block;
        position: relative;
        margin: 0 auto;
        padding: .25rem;
        transform: rotate(90deg);
        width: 2rem;
        z-index: 1;
    }
}