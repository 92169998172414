@import './config';

html,
body,
#root {
    height: 100%;
    font-family: $font-family-default;
    font-size: $font-size-default;
    line-height: $line-height;
    margin: 0;
    padding: 0;
    width: 100%;
}

body {
    * {
        box-sizing: border-box;
    }

    >div {
        >*:not(header) {
            background-color: $container-color;
        }
    }
}

button,
a:link,
a:visited {
    &.readmore {
        align-items: center;
        align-self: flex-start;
        background: none;
        border: 5px solid;
        border-radius: 10rem;
        cursor: pointer;
        display: flex;
        font-size: 1rem;
        font-weight: 600;
        font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        margin-top: 1rem;
        padding: 0.6rem 1.2rem;
        pointer-events: visible;
        text-decoration: none;

        &[data-color-inverted="true"] {
            border-color: $container-color;
            color: $text-color-inverted;
        }

        &[data-has-icon="true"]:not(:disabled) {
            svg {
                margin-left: 0;
                transition: margin-left .15s cubic-bezier(0.800, 0.210, 0.360, 1.320), width .15s cubic-bezier(0.800, 0.210, 0.360, 1.320);
                width: 0;
                will-change: width;
            }
    
            &:hover {
                svg {
                    margin-left: .5rem;
                    transition: .2s margin-left .15s cubic-bezier(0.800, 0.210, 0.360, 1.320), .2s width .15s cubic-bezier(0.800, 0.210, 0.360, 1.320);
                    width: 1.2rem;
                    will-change: width;
                }
            }
        }
    }
}

a:link,
a:visited {
    &.close:not(.readmore){
        align-items: center;
        background-color: transparentize($color: $black, $amount: .85);
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
        display: inline-flex;
        justify-content: center;
        left: 0;
        margin: 0 auto;
        padding: .25rem 0;
        position: absolute;
        top: 0;
        transition: .2s background-color .15s cubic-bezier(0.800, 0.210, 0.360, 1.320);
        width: 100%;
        will-change: color;

        &:hover{
            background-color: transparentize($color: $black, $amount: .65);
            transition: .2s background-color .15s cubic-bezier(0.800, 0.210, 0.360, 1.320);
            will-change: color;
        }
    
        &[data-color-inverted="true"] {
            svg{
                path{
                    stroke: $container-color;
                }
            }
        }
        
        @media #{$small-and-down} {
            height: 2.5rem;
        }
    }
}

button {
    align-items: center;
    align-self: flex-start;
    background-color: darkgray;
    border: 5px solid darkgray;
    border-radius: 10rem;
    color: $text-color-inverted;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    padding: 0.6rem 1.2rem;
    pointer-events: visible;
    text-decoration: none;

    &[data-color-inverted="true"] {
        border-color: $container-color;
        color: $text-color-inverted;
    }

    &:disabled {
        background-color: transparent;
        border: 5px solid lightgray;
        color: lightgray;
        svg{
            display: none;
        }
    }
    
    &[data-has-icon="true"]:not(:disabled) {
        svg {
            margin-left: 0;
            transition: margin-left .15s cubic-bezier(0.800, 0.210, 0.360, 1.320), width .15s cubic-bezier(0.800, 0.210, 0.360, 1.320);
            width: 0;
            will-change: width;
        }

        &:hover {
            svg {
                margin-left: .5rem;
                transition: .2s margin-left .15s cubic-bezier(0.800, 0.210, 0.360, 1.320), .2s width .15s cubic-bezier(0.800, 0.210, 0.360, 1.320);
                width: 1.2rem;
                will-change: width;
            }
        }
    }
}

.inputGroup {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 1rem;
}

label {
    color: lighten($color: #000000, $amount: 60%);
    font-size: .75rem;
    font-weight: 600;
    opacity: .75;
    transition: color .3s cubic-bezier(0.800, 0.210, 0.360, 1.320), opacity .3s cubic-bezier(0.800, 0.210, 0.360, 1.320);
    will-change: color, opacity;

    span {
        color: red;
    }
}

input,
select,
textarea {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 100px;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 0.7rem;
    height: 3rem;
    padding: 1rem .5rem;

    &:focus {
        +label {
            color: black;
            opacity: 1;
            transition: color .3s cubic-bezier(0.800, 0.210, 0.360, 1.320), opacity .3s cubic-bezier(0.800, 0.210, 0.360, 1.320);
            will-change: color, opacity;
        }
    }
}

select {
    padding-right: 2rem;
}

textarea {
    border-radius: 10px;
    height: 100% !important;
    width: 100% !important;
}