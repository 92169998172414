@import '../../config';

.tagsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0 .5rem 0;

    .tag {
        border: 2px solid $gray;
        border-radius: 3rem;
        color: $gray;
        font-size: .75rem;
        font-weight: 600;
        line-height: 1rem;
        margin-bottom: .5rem;
        margin-right: .5rem;
        padding: .25rem .5rem;
    }
}