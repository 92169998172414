@import '../../config';

.headerContainer {
    align-items: center;
    background-color: $black;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;

    h1,
    h2 {
        color: $text-color-inverted;
        font-weight: 100;
    }

    .payoff {
        font-size: 1.5rem;
    }

    .stories {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;

        strong {
            display: block;
            text-transform: uppercase;
        }
    }

    .forground {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        overflow: hidden;
        width: 100%;
        z-index: 1;
        .ionicon{
            animation: arrowSignaling 3s ease-in-out 5s infinite;
            transform: scale(1);
            transition: transform .5s cubic-bezier(0.800, 0.210, 0.360, 1.320);
            &:hover{
                transform: scale(1.5);
                transition: transform .5s cubic-bezier(0.800, 0.210, 0.360, 1.320);
            }
        }
    }

    video {
        opacity: .5;
        position: fixed;
        width: 200%;
        z-index: 0;
        @media #{$small-and-down} {
            display: none;
        }
    }
}

@keyframes arrowSignaling {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0rem);
    }
}